"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DishonestExpertOrganizationsPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var React = __importStar(require("react"));
var constants_1 = require("../../../../../constants");
var helpers_1 = require("../../../../../helpers");
var useTableFilters_1 = require("../../../../../hooks/useTableFilters");
var S = __importStar(require("../../../../../styles"));
var filter_values_1 = require("../../../../ud-ui/components/filter-values");
var form_calendar_1 = require("../../../../ud-ui/components/form-calendar");
var DataGrid_1 = require("../../../../ud-ui/components/table/DataGrid");
var constants_2 = require("../../../domain/constants");
var getDishonestExpertOrganizationColumns_1 = require("../../../domain/helpers/getDishonestExpertOrganizationColumns");
var reportsApi_1 = require("../../../store/api/reportsApi");
var dishonest_expert_organizations_export_excel_1 = require("../../components/dishonest-expert-organizations-export-excel");
var defaultFilters = {
    period: '',
};
var DishonestExpertOrganizationsPage = function () {
    var _a, _b, _c, _d;
    var _e = (0, useTableFilters_1.useTableFilters)(constants_1.LOCATION_KEYS.dishonestExpertOrganizations, constants_2.dishonestExpertOrganizationsFilters, defaultFilters), tableFilters = _e.tableFilters, pagination = _e.pagination, changeFilter = _e.changeFilter, changeTableFilter = _e.changeTableFilter, filters = _e.filters, deleteTableFilter = _e.deleteTableFilter, getTableFiltersParams = _e.getTableFiltersParams, setTableFilter = _e.setTableFilter, setPageParams = _e.setPageParams;
    var queryParams = __assign(__assign(__assign({}, getTableFiltersParams()), (0, helpers_1.getPaginationParams)(pagination)), { period: (0, helpers_1.getIsoEndDate)(filters.period) });
    var dishonestExpertOrganizations = (0, reportsApi_1.useFetchDishonestExpertOrganizationsQuery)((0, helpers_1.getQueryParams)(queryParams));
    var dataGridProps = React.useMemo(function () {
        var _a, _b, _c, _d;
        return ({
            columns: (0, getDishonestExpertOrganizationColumns_1.getDishonestExpertOrganizationColumns)({
                onSubmit: setTableFilter,
            }),
            rows: (_b = (_a = dishonestExpertOrganizations.data) === null || _a === void 0 ? void 0 : _a.rows) !== null && _b !== void 0 ? _b : [],
            rowCount: (_d = (_c = dishonestExpertOrganizations.data) === null || _c === void 0 ? void 0 : _c.total) !== null && _d !== void 0 ? _d : 0,
        });
    }, [dishonestExpertOrganizations.data]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(S.FiltersLayout, __assign({ "data-id": "filterDishonestExpertOrganizations" }, { children: [(0, jsx_runtime_1.jsx)(S.FiltersWrapBlock, { children: (0, jsx_runtime_1.jsx)(form_calendar_1.FormCalendar, { className: "filtersLayout", dataId: "period", isClearable: true, fieldTitle: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043F\u0435\u0440\u0438\u043E\u0434", fieldValue: filters.period, setFieldValue: changeFilter('period') }) }), (0, jsx_runtime_1.jsx)(S.FiltersBlock, { children: (0, jsx_runtime_1.jsx)(dishonest_expert_organizations_export_excel_1.DishonestExpertOrganizationsExportExcelButton, { params: queryParams }) })] })), (0, jsx_runtime_1.jsx)(filter_values_1.FilterValues, { values: tableFilters, onDelete: deleteTableFilter, onChange: changeTableFilter }), (0, jsx_runtime_1.jsx)(DataGrid_1.UDDataGrid, __assign({}, dataGridProps, pagination, { isLoading: dishonestExpertOrganizations.isFetching, rows: (_b = (_a = dishonestExpertOrganizations.data) === null || _a === void 0 ? void 0 : _a.rows) !== null && _b !== void 0 ? _b : [], rowCount: (_d = (_c = dishonestExpertOrganizations.data) === null || _c === void 0 ? void 0 : _c.total) !== null && _d !== void 0 ? _d : 0, onPageModeChange: setPageParams }))] }));
};
exports.DishonestExpertOrganizationsPage = DishonestExpertOrganizationsPage;
